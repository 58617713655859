import React from 'react'

import Layout from '~components/Layout'
import SEO from '~components/Seo'
import Header from '~components/Header'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Header>
      <h1>404</h1>
      <p>Not found</p>
    </Header>
  </Layout>
)

export default NotFoundPage
